import { Link } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/404.css"
const NotFoundPage = () => (
  <Layout footer_layout="l400" colored_header={true}>
    <Seo title="404: Not found" />
    <div className="page-404">
       <div className="content">
         <span className="ops">OOPS!</span>
          <h1>PAGE NOT FOUND.</h1>
          <Link className="btn-primary" to="/">Back to Homepage</Link>
       </div>
    </div>
  </Layout>
)

export default NotFoundPage
